import React, { Component } from "react"

// Helpers
import NumberUtils from "~helpers/numberUtils"
import anime from "animejs/lib/anime.es.js"

// Styles
import Styles from "./CanvasBoolean.module.styl"

// Libs
const random = require("canvas-sketch-util/random")

class CanvasBoolean extends Component {
    constructor(props) {
        super(props)

        // ES6 Rebind
        this.onMouseOverLeft = this.onMouseOverLeft.bind(this)
        this.onMouseOverRight = this.onMouseOverRight.bind(this)
        this.onMouseOutRight = this.onMouseOutRight.bind(this)
        this.onMouseOutLeft = this.onMouseOutLeft.bind(this)
        this.onResize = this.onResize.bind(this)
        this.onClickBtn = this.onClickBtn.bind(this)

        // Refs
        this.canvasWrapper = React.createRef()
        this.canvas = React.createRef()
        this.buttonsWrapper = React.createRef()
        this.buttonLeft = React.createRef()
        this.buttonRight = React.createRef()
        this.indicatorNo = React.createRef()
        this.indicatorYes = React.createRef()
    }

    state = {
        selectedIndex: -1,
    }

    componentWillMount() {
        this.mouse = {
            x: 0,
            y: 0,
        }

        this.positionBtn = null

        this.scaleLineLeft = 1
        this.scaleLineTransitionLeft = 1
        this.scaleLineRight = 1
        this.scaleLineTransitionRight = 1
    }

    componentDidMount() {
        this.createCanvas()
        this.setCanvasSizes()
        this.bindEvents()
        this.draw()
    }

    componentWillUnmount() {
        this.unbindEvents()
        this.undraw()
    }

    bindEvents() {
        window.addEventListener("resize", this.onResize)
    }

    unbindEvents() {
        window.removeEventListener("resize", this.onResize)
    }

    onMouseOverLeft(e) {
        this.positionBtn = "left"

        const mp = { x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY }
        const ts = {
            width: this.buttonLeft.current.getBoundingClientRect().width,
            height: this.buttonLeft.current.getBoundingClientRect().height,
        }
        const walk = 10
        const xWalk = (mp.x / ts.width) * (walk * 2) - walk
        const yWalk = (mp.y / ts.height) * (walk * 2) - walk

        anime({
            targets: this.indicatorYes.current,
            translateX: xWalk,
            translateY: yWalk,
            scale: 1.3,
            duration: 100,
        })
    }

    onMouseOverRight(e) {
        this.positionBtn = "right"

        const mp = { x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY }
        const ts = {
            width: this.buttonRight.current.getBoundingClientRect().width,
            height: this.buttonRight.current.getBoundingClientRect().height,
        }
        const walk = 10
        const xWalk = (mp.x / ts.width) * (walk * 2) - walk
        const yWalk = (mp.y / ts.height) * (walk * 2) - walk

        anime({
            targets: this.indicatorNo.current,
            translateX: xWalk,
            translateY: yWalk,
            scale: 1.3,
            duration: 100,
        })
    }

    onMouseOutLeft() {
        this.positionBtn = null

        setTimeout(() => {
            anime({
                targets: this.indicatorYes.current,
                translateX: 0,
                translateY: 0,
                scale: 1.3,
                duration: 100,
                easing: "easeInOutSine",
            })
        }, 100)
    }

    onMouseOutRight() {
        this.positionBtn = null

        setTimeout(() => {
            anime({
                targets: this.indicatorNo.current,
                translateX: 0,
                translateY: 0,
                duration: 100,
                scale: 1.3,
                easing: "easeInOutSine",
            })
        }, 100)
    }

    onMouseMove(e) {
        this.mouse.x = e.nativeEvent.offsetX
        this.mouse.y = e.nativeEvent.offsetY
    }

    onClickBtn(index) {
        this.setState(
            {
                selectedIndex: index,
            },
            () => {
                this.props.onClickBtn(
                    index,
                    this.props.choices[index].conditionalTarget
                )
            }
        )
    }

    onResize() {
        this.setCanvasSizes()
    }

    createCanvas() {
        this.ratio = window.devicePixelRatio
        this.ctx = this.canvas.current.getContext("2d")
    }

    setCanvasSizes() {
        this.canvas.current.width =
            this.canvasWrapper.current.getBoundingClientRect().width *
            this.ratio
        this.canvas.current.height =
            this.canvasWrapper.current.getBoundingClientRect().height *
            this.ratio

        this.canvas.current.style.width = `${
            this.canvasWrapper.current.getBoundingClientRect().width
        }px`
        this.canvas.current.style.height = `${
            this.canvasWrapper.current.getBoundingClientRect().height
        }px`

        this.width = this.canvas.current.width / this.ratio
        this.height = this.canvas.current.height / this.ratio

        this.widthRef = (this.width / 2) * 0.8
        this.heightRef = (this.height / 2) * 0.8
    }

    undraw() {
        cancelAnimationFrame(this.raf)
    }

    draw() {
        this.time = Date.now() / 2000

        this.ctx.save()
        // SCALE RATIO FOR DEVICE
        this.ctx.scale(this.ratio, this.ratio)
        this.ctx.fillStyle = "rgba(0, 0, 0, 0)"
        this.ctx.clearRect(0, 0, this.width, this.height)
        this.ctx.fillRect(0, 0, this.width, this.height)

        this.updateLineScale()
        //I Didn't use 0 - Math.PI/2 because the angle 0 start at the right and not at the top of the circle
        this.drawHalfCircle(
            this.scaleLineTransitionRight,
            -Math.PI / 2,
            Math.PI - Math.PI / 2
        )
        this.drawHalfCircle(
            this.scaleLineTransitionLeft,
            Math.PI - Math.PI / 2,
            Math.PI + Math.PI / 2
        )

        this.ctx.restore()

        this.raf = requestAnimationFrame(this.draw.bind(this))
    }

    drawHalfCircle(transition, valueStart, valueEnd) {
        const totalLines = 100

        for (let i = 0; i <= totalLines; i++) {
            const angle = NumberUtils.mapRange(
                i,
                0,
                totalLines,
                valueStart,
                valueEnd
            )

            // const s =  (Math.sin(angle/2) + 1) / 2;

            const x1 = this.width / 2 + Math.cos(angle) * this.widthRef
            const y1 = this.height / 2 + Math.sin(angle) * this.widthRef

            //CALC OF NOISE VALUE
            const nx = x1 / this.width - 0.5
            const ny = y1 / this.height - 0.5
            let n =
                1 * random.noise2D(0.6 * nx, 0.6 * ny + this.time) +
                0.5 * random.noise2D(2 * nx, 2 * ny + this.time) +
                0.25 * random.noise2D(4 * nx, 2 * ny + this.time)

            //SIZE OF BLOB LINES
            let scalar = NumberUtils.mapRange(
                n,
                -1,
                1,
                this.widthRef + 2,
                (this.widthRef + 2) * transition
            )

            //DRAW LINES
            const x2 = this.width / 2 + Math.cos(angle) * scalar
            const y2 = this.height / 2 + Math.sin(angle) * scalar

            this.ctx.beginPath()
            this.ctx.moveTo(x1, y1)
            this.ctx.lineTo(x1, y1)
            this.ctx.lineTo(x2, y2)
            this.ctx.closePath()
            this.ctx.stroke()
        }
    }

    updateLineScale() {
        if (this.positionBtn === "left") {
            this.scaleLineLeft = 1.2
        } else {
            this.scaleLineLeft = 1
        }

        if (this.positionBtn === "right") {
            this.scaleLineRight = 1.2
        } else {
            this.scaleLineRight = 1
        }

        this.scaleLineTransitionLeft = NumberUtils.lerp(
            this.scaleLineTransitionLeft,
            this.scaleLineLeft,
            0.1
        )
        this.scaleLineTransitionRight = NumberUtils.lerp(
            this.scaleLineTransitionRight,
            this.scaleLineRight,
            0.1
        )
    }

    render() {
        return (
            <div className={`${Styles.CanvasBoolean}`}>
                <div
                    className={`${Styles.CanvasBoolean__canvas}`}
                    ref={this.canvasWrapper}
                >
                    <canvas
                        className={`${Styles.CanvasBoolean__canvas__item}`}
                        ref={this.canvas}
                    ></canvas>
                </div>

                <div
                    className={`${Styles.CanvasBoolean__buttons}`}
                    ref={this.buttonsWrapper}
                    onMouseMove={(e) => {
                        e.persist()
                        this.onMouseMove(e)
                    }}
                >
                    <div
                        className={`${Styles.CanvasBoolean__buttons__item} ${
                            this.state.selectedIndex === 0
                                ? Styles.CanvasBoolean__buttons__item__active
                                : ""
                        } ${Styles.CanvasBoolean__buttons__item__left}`}
                        onMouseOut={this.onMouseOutLeft}
                        onMouseMove={this.onMouseOverLeft}
                        onMouseOver={this.onMouseOverLeft}
                        onClick={() => {
                            this.onClickBtn(0)
                        }}
                        ref={this.buttonLeft}
                    >
                        <p
                            className={`${Styles.CanvasBoolean__button__text} teasing-1`}
                            onMouseMove={this.onMouseOverLeft}
                            onMouseOver={this.onMouseOverLeft}
                            ref={this.indicatorYes}
                        >
                            {this.props.choices[0].title}
                        </p>
                    </div>

                    <div
                        className={`${Styles.CanvasBoolean__buttons__item} ${
                            this.state.selectedIndex === 1
                                ? Styles.CanvasBoolean__buttons__item__active
                                : ""
                        } ${Styles.CanvasBoolean__buttons__item__right}`}
                        onMouseOut={this.onMouseOutRight}
                        onMouseMove={this.onMouseOverRight}
                        onMouseOver={this.onMouseOverRight}
                        onClick={() => {
                            this.onClickBtn(1)
                        }}
                        ref={this.buttonRight}
                    >
                        <p
                            className={`${Styles.CanvasBoolean__button__text} teasing-1`}
                            onMouseMove={this.onMouseOverRight}
                            onMouseOver={this.onMouseOverRight}
                            ref={this.indicatorNo}
                        >
                            {this.props.choices[1].title}
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default CanvasBoolean
