import React, { Component } from "react"
import { Link, navigate } from "gatsby"

// Store
import QuestionStore from "~stores/QuestionStore"

// Components
import { Row, Cell } from "~layout/Layout"
import QuestionWrapper from "~components/Questions/QuestionsCommons/QuestionWrapper"
import QuestionMetas from "~components/Questions/QuestionsCommons/QuestionMetas"
import QuestionBtnNext from "~components/Questions/QuestionsCommons/QuestionBtnNext"
import CanvasBoolean from "./CanvasBoolean"

// Styles
import Styles from "./QuestionBoolean.module.styl"

// Libs
import anime from "animejs/lib/anime.es.js"

class QuestionBoolean extends Component {
    constructor(props) {
        super(props)

        // ES6 Rebind
        this.onChangeRadio = this.onChangeRadio.bind(this)
        this.goToNext = this.goToNext.bind(this)

        // Refs
        this.questionLeft = React.createRef()
        this.questionRight = React.createRef()
    }

    state = {
        questionData: this.props.pageContext,
        score: -1,
        nextQuestion: this.props.pageContext.next,
    }

    componentDidMount() {
        this.checkQuestionFromStore(this.state.questionData.id)

        this.setEnterAnimation()
    }

    setEnterAnimation() {
        const timeline = anime.timeline({
            easing: "easeInOutExpo",
            delay: 200,
        })

        timeline.add({
            targets: this.questionLeft.current,
            translateX: ["1rem", 0],
            opacity: [0, 1],
            duration: 400,
        })

        timeline.add(
            {
                targets: this.questionRight.current,
                translateX: ["1rem", 0],
                opacity: [0, 1],
                duration: 400,
                complete: (anim) => {
                    if (this.questionRight && this.questionRight.current) {
                        this.questionRight.current.style.transform = ``
                    }
                },
            },
            100
        )
    }

    setExitAnimation(callback) {
        const timeline = anime.timeline({
            easing: "easeInOutExpo",
            delay: 200,
        })

        timeline.add({
            targets: this.questionLeft.current,
            translateX: [0, "-1rem"],
            opacity: [1, 0],
            duration: 400,
        })

        timeline.add(
            {
                targets: this.questionRight.current,
                translateX: [0, "-1rem"],
                opacity: [1, 0],
                duration: 400,
                complete: (anim) => {
                    callback()
                },
            },
            100
        )
    }

    checkQuestionFromStore(index) {
        if (!QuestionStore.checkIfEverAnswered()) {
            navigate(`/questions/1`)
            return
        }

        const foundIndex = QuestionStore.checkForQuestion(
            this.props.pageContext.id
        )

        if (foundIndex > -1) {
            QuestionStore.removeQuestionAtIndex(foundIndex)
        }
    }

    onChangeRadio(index, isConditional) {
        this.setState({
            label: this.state.questionData.choices[index].title,
            score: this.state.questionData.choices[index].score * 1,
        })

        if (this.state.questionData.isConditional) {
            if (isConditional) {
                this.setState({
                    nextQuestion: this.state.questionData.conditionalGoto,
                })
            } else {
                this.setState({
                    nextQuestion: this.state.questionData.next,
                })
            }
        } else {
            this.setState({
                nextQuestion: this.state.questionData.next,
            })
        }
    }

    goToNext() {
        QuestionStore.updateScore(this.state.questionData.id, [
            {
                label: this.state.label,
                jar: this.state.questionData.jar,
                score: this.state.score,
            },
        ])

        this.setExitAnimation(() => {
            navigate(`/questions/${this.state.nextQuestion}`)
        })
    }

    render() {
        return (
            <QuestionWrapper
                currentQuestionID={this.state.questionData.id}
                currentTheme={this.state.questionData.theme}
            >
                <div className={Styles.QuestionBoolean}>
                    <div className={Styles.QuestionBoolean_inner}>
                        <Row>
                            <Cell start="1" end="5" startTp="0" endTp="12">
                                <div
                                    className={Styles.QuestionBoolean__left}
                                    ref={this.questionLeft}
                                >
                                    <QuestionMetas
                                        data={this.state.questionData}
                                    />
                                </div>
                            </Cell>
                            <Cell start="6" end="11" startTp="0" endTp="12">
                                <div
                                    className={Styles.QuestionBoolean__right}
                                    ref={this.questionRight}
                                >
                                    <CanvasBoolean
                                        choices={
                                            this.state.questionData.choices
                                        }
                                        onClickBtn={this.onChangeRadio}
                                    />
                                </div>
                            </Cell>
                        </Row>
                    </div>
                </div>

                {this.state.score >= 0 && (
                    <QuestionBtnNext
                        onClick={this.goToNext.bind(this)}
                        label={"Confirmer"}
                    />
                )}
            </QuestionWrapper>
        )
    }
}

export default QuestionBoolean
